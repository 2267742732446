/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// import { Line } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  // Card,
  // CardHeader,
  // CardBody,
  // CardFooter,
  // CardTitle,
  // ListGroupItem,
  // ListGroup,
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import ReactLoading from 'react-loading';
import classnames from "classnames";
// import bigChartData from "variables/charts.js";
const { TinyURL } = require("../../services/tinyURL");
const { CuttLY } = require("../../services/cuttLY");
export default function LandingPage() {
  const tinyURL = new TinyURL();
  const cuttLY = new CuttLY();
  const [username, setUser] = React.useState("");
  const [password, setPass] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [miniModal, setMiniModal] = React.useState(false);
  const [m3u8, setM3u8] = React.useState(false);
  const [shortm3u8, setShortM3u8] = React.useState(false);
  const [shortm3u8Alt, setShortM3u8Alt] = React.useState(false);
  const [ts, setTS] = React.useState(false);
  const [shortts, setShortTs] = React.useState(false);
  const [shorttsAlt, setShortTsAlt] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);
  // const generateLinks = async (domain, username, password) => {
  //   document.getElementById("loading").removeAttribute("hidden");
  //   let m3u8 = `http://${domain}/get.php?username=${username}&password=${password}&type=m3u_plus&output=m3u8`;
  //   let shortm3u8 = await tinyURL.shortURL(`http://${domain}/get.php?username=${username}&password=${password}&type=m3u_plus&output=m3u8`, username, password);
  //   let ts = `http://${domain}/get.php?username=${username}&password=${password}&type=m3u_plus&output=mpegts`;
  //   let shortts = await tinyURL.shortURL(`http://${domain}/get.php?username=${username}&password=${password}&type=m3u_plus&output=mpegts`, username, password);

  //   let shortm3u8alt = await cuttLY.shortURL(`http://${domain}/get.php?username=${username}&password=${password}&type=m3u_plus&output=m3u8`, username, password);
  //   let shorttsalt = await cuttLY.shortURL(`http://${domain}/get.php?username=${username}&password=${password}&type=m3u_plus&output=mpegts`, username, password);
  //   //setM3u8(m3u8);
  //   setShortM3u8(shortm3u8?.tiny_url);
  //   //setTS(ts);
  //   setShortTs(shortts?.tiny_url);
  //   setShortTsAlt(shorttsalt);
  //   setShortM3u8Alt(shortm3u8alt);
  //   document.getElementById("loading").setAttribute("hidden", "");
  //   setMiniModal(true);

  // }
  const generateLinks = async (domain, username, password) => {
    // Show loading indicator
    document.getElementById("loading").removeAttribute("hidden");
  
    // Base URLs
    const m3u8Url = `http://${domain}/playlist/${username}/${password}/m3u_plus`;
    const tsUrl = encodeURIComponent(`http://${domain}/play/list?username=${username}&password=${password}&type=m3u_plus&output=ts`);
    const tsUrltiny = encodeURI(`http://${domain}/play/list?username=${username}&password=${password}&type=m3u_plus&output=ts`);
  
    try {
      // Execute requests in parallel with Promise.all
      const [
        shortM3u8,
        shortTs,
        shortM3u8Alt,
        shortTsAlt
      ] = await Promise.all([
        tinyURL.shortURL(m3u8Url, username, password),
        tinyURL.shortURL(tsUrltiny, username, password),
        cuttLY.shortURL(m3u8Url, username, password),
        cuttLY.shortURL(tsUrl, username, password)
      ]);
  
      // Update state with shortened URLs
      setShortM3u8(shortM3u8?.tiny_url);
      setShortTs(shortTs?.tiny_url);
      setShortM3u8Alt(shortM3u8Alt);
      setShortTsAlt(shortTsAlt);
  
    } catch (error) {
      console.error("Error generating shortened links:", error);
    } finally {
      // Hide loading indicator
      document.getElementById("loading").setAttribute("hidden", "");
      // Show modal
      setMiniModal(true);
    }
  };
  
  const modalRef = React.useRef(null);
  const m3Ref = React.useRef(null);
  const m3altRef = React.useRef(null);
  const tsRef = React.useRef(null);
  const tsaltRef = React.useRef(null);
  const handleCopyClick = (ref) => {
    if (ref.current) {
      const range = document.createRange();
      range.selectNodeContents(ref.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      const formattedContent = selection;
      //const formattedContent = selection.toString().replace(/\s+/g, "\n");
      navigator.clipboard.writeText(formattedContent);
      //document.getElementById("copy").innerText = "Copiado!";
    }
  }
  return (
    <>
      <div className="wrapper">
        <section className="section section-lg">
          <section className="section">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container>
              <Row className="justify-content-center">
                <Col md="5">
                  <div className="">
                    <h1 style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    Generate IPTV List
                    </h1>
                    <Form className="form">
                      <InputGroup
                        className={classnames({
                          "input-group-focus": fullNameFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => { setUser(e.target.value) }}
                          placeholder="Username"
                          type="text"
                          value={username}
                          onFocus={(e) => setFullNameFocus(true)}
                          onBlur={(e) => setFullNameFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": emailFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="text"
                          onChange={(e) => { setPass(e.target.value) }}
                          value={password}
                          onFocus={(e) => setEmailFocus(true)}
                          onBlur={(e) => setEmailFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": passwordFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-app" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="select"
                          onChange={(e) => { setDomain(e.target.value) }}
                          onFocus={(e) => setPasswordFocus(true)}
                          onBlur={(e) => setPasswordFocus(false)}
                        >
                          <option className="text-dark" value={null}>Select the Domain</option>
                          <option className="text-dark" value={'psdgdrsfdthsdrggdrgdrgsefsensserdqdqwczsr.xn--b1aai2cf3d.com'}>psdgdrsfdthsdrggdrgdrgsefsensserdqdqwczsr.весьтв.com</option>
                        </Input>
                      </InputGroup>
                    </Form>
                    <Button className="btn-round btn-block" onClick={() => { generateLinks(domain, username, password) }} color="primary" size="sm">
                    Generate List
                    </Button>
                    <div id="loading" hidden style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}><ReactLoading type={'bars'} color={'#be00e8'} height={'20%'} width={'20%'} /></div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <Modal
          modalClassName="modal-secondary"
          isOpen={miniModal}
          size="md"
          scrollable
          toggle={() => setMiniModal(false)}
        >
          <div className="wrapper">
            <div className="modal-header justify-content-center">
              <button className="close" onClick={() => setMiniModal(false)}>
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <div className="modal-profile">
                <i className="tim-icons icon-single-02" />
              </div>
            </div>
            <div className="modal-body" style={{fontSize:"12px"}}>
              <h3 className="text-dark">Successfully Generated Links!</h3>
              <div ref={modalRef}>

                <p>OPTION 1: </p>
                {/* <p><span>MPEGTS: </span><a href={ts}>{ts}</a></p> */}
                <p><span ref={tsRef}><span>SHORTENED MPEGTS: </span><a href={shortts}>{shortts}</a></span>&nbsp;
                  <Button size="sm" color="primary"  id="copy1" onClick={() => { handleCopyClick(tsRef) }}>
                    Copiar
                  </Button>
                </p>
                {/* <p><span>M3U8: </span><a href={m3u8}>{m3u8}</a></p> */}
                <p><span ref={m3Ref}><span>SHORTENED M3U8: </span><a href={shortm3u8}>{shortm3u8}</a></span>&nbsp;
                  <Button  size="sm" color="primary"  id="copy2" onClick={() => { handleCopyClick(m3Ref) }}>
                    Copiar
                  </Button>
                </p>
                <br></br>
                <p>OPTION 2: </p>
                <p><span ref={tsaltRef}><span>ALTERNATIVE SHORTENED MPEGTS: </span><a href={shorttsAlt}>{shorttsAlt}</a></span>&nbsp;
                <Button size="sm" color="primary"  id="copy3" onClick={() => { handleCopyClick(tsaltRef) }}>
                    Copiar
                  </Button>
                </p>
                <p><span ref={m3altRef}><span>ALTERNATIVE SHORTENED M3U8: </span><a href={shortm3u8Alt}>{shortm3u8Alt}</a></span>&nbsp;
                  <Button  size="sm" color="primary"  id="copy4" onClick={() => { handleCopyClick(m3altRef) }}>
                    Copiar
                  </Button>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button size="sm" color="primary" id="copy" onClick={() => { handleCopyClick(modalRef) }}>
                Copiar
              </Button>
              <Button
                className="btn-neutral"
                size="sm"
                color="link"
                onClick={() => setMiniModal(false)}
                type="button"
              >
                <span className="text-dark">Fechar</span>
              </Button>
            </div>
          </div>
        </Modal>
        {/* <Footer /> */}
      </div>
    </>
  );
}
