/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss";
import "assets/demo/demo.css";

import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import LoginPage from "views/examples/LoginPage";
import { AuthProvider } from "auth/AuthContext";
import PrivateRoute from "auth/PrivateRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/generate" component={LandingPage} />
        {/* <Route
          path="/register-page"
          render={(props) => <RegisterPage {...props} />}
        /> */}
        <Route
          path="/login"
          render={(props) => <LoginPage {...props} />}
        />
        {/* <PrivateRoute
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        /> */}
        <Redirect from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  </AuthProvider>
);
