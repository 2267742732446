import axios from 'axios';

export class CuttLY {
    shortURL = async (url, username, password) => {
        try {
            let response = await axios.post(`https://api.generetoriptvxtreamformat.net/shorten-url`,
            {
                "url": url,
            }
            );
            return response.data.url.shortLink;
        } catch (error) {
            return await error;
        }
    }
}
