import axios from 'axios';

export class ApiPanel {
    login = async (username, password) => {
        try {
            let response = await axios.post(`https://api.myidvirtual.com/auth/signin/passid`,
                {
                    "username": username,
                    "password_passId": password
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                });

            return response.data;
        } catch (error) {
            console.log("Erroooooo", error)
            throw error;
        }
    }
}
