import React, { createContext, useContext, useState, useEffect } from "react";
import { Redirect} from "react-router-dom";

// Criando o contexto
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser && JSON.parse(storedUser).access_token && JSON.parse(storedUser).userReturn) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (userData) => {
    if(userData.access_token && userData.userReturn){
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    document.location='/login'
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
