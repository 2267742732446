import React from "react";
import { Redirect, Route } from "react-router-dom"; // Usando o Redirect no v5.x
// import { useAuth } from "./AuthContext"; // Importa o contexto de autenticação

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        localStorage.getItem('user') 
        && JSON.parse(localStorage.getItem('user')).access_token 
        && JSON.parse(localStorage.getItem('user')).userReturn ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
      }
    />
  );
};

export default PrivateRoute;
