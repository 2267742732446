import axios from 'axios';

export class TinyURL {
    shortURL = async (url, username, password) => {
        try {
            let response = await axios.post(`https://api.tinyurl.com/create`,
                {
                    "url": url,
                },
                {
                    headers: {
                        "authorization": "Bearer czU8gWwXmrrLGwBV8iMOrmLgwcazh75omfnweNlHyCcGLLF3wfDJbujQDRG3",
                    }
                });

            return response.data.data;
        } catch (error) {
            return await error;
        }
    }
}
