/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { useAuth } from "../../auth/AuthContext";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Toast,
  ToastHeader,
  ToastBody
} from "reactstrap";
import ReactLoading from 'react-loading';
// core components
import Footer from "components/Footer/Footer.js";
import { Redirect} from "react-router-dom";
const { ApiPanel } = require("../../services/api");

export default function LoginPage() {
  const apiService = new ApiPanel();
  const { login } = useAuth();
  const [username, setUser] = React.useState("");
  const [password, setPass] = React.useState("");
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);


  const sign = async (username, password) => {
    if(!username || !password) {
        setErrorMessage("Preencha todos os campos.");
        setShowToast(true);
        return;
    }
    // Mostrar loading
    document.getElementById("loading").removeAttribute("hidden");

    try {
      const loginResponse = await apiService.login(username, password);
      console.log("login", loginResponse);
      login(loginResponse);

      // Se o login for bem-sucedido, redireciona para /generate
      // <Redirect to="/generate" />
      document.location='/generate'

    } catch (error) {
      console.error("Error login:", error);

      // Exibir Toast com mensagem de erro
      setErrorMessage(error.response?.data?.message || "Erro ao fazer login");
      setShowToast(true);
    } finally {
      // Ocultar loading
      document.getElementById("loading").setAttribute("hidden", "");
    }
  };


  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    );
  };
  return (
    <>
    <div
        className="position-fixed top-0 start-0 p-3 bg-primary bg-primary my-2 rounded"
        style={{ zIndex: 1050, color: 'white' }}
        hidden={!showToast}
    >
    <Toast isOpen={showToast} fade={true} style={{ minWidth: "250px", maxWidth: "300px" }}>
        <ToastHeader toggle={() => setShowToast(false)}>Erro</ToastHeader>
        <ToastBody color="white">{errorMessage}</ToastBody>
    </Toast>
    </div>
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("assets/img/square-purple-1.png")}
                      />
                      <CardTitle tag="h4" style={{ color: "white", textAlign: "center" }}>Login</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": fullNameFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Username"
                            type="text"
                            onChange={(e) => { setUser(e.target.value) }}
                            onFocus={(e) => setFullNameFocus(true)}
                            onBlur={(e) => setFullNameFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": passwordFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => { setPass(e.target.value) }}
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                          />
                        <InputGroupText 
                            onClick={() => setShowPassword(!showPassword)} 
                            style={{ cursor: "pointer" }}
                        >
                        {showPassword ? <i className="tim-icons icon-key-25" /> : <i className="tim-icons icon-lock-circle" />}
                        </InputGroupText>
                        </InputGroup>
                      </Form>
                    </CardBody>
                    <CardFooter>
                      <Button className="btn-round" color="primary" size="lg" onClick={async () => { await sign(username, password) }}>
                        Login
                      </Button>
                      <div id="loading" hidden style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        }}><ReactLoading type={'bars'} color={'#be00e8'} height={'20%'} width={'20%'} /></div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
